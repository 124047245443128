<template>
  <d-container fluid>
    <pageTitle title="Overzicht" subtitle="Aankoop overzicht"/>
    <d-row>
      <vCard :col="12" header="Aankoop overzicht" fluid>
        <vTable :fields="fields" :data="list" :actions="actions"/>
<!--        <pre>{{ list }}</pre>-->
      </vCard>
    </d-row>
  </d-container>
</template>

<script>

export default {
  name: 'purchaseList',
  title: 'Aankoop lijst',
  components: {
  },
  created() {
    this.getPurchases()
  },
  data() {
    return {
      list: null,
      fields: [
        {
          name: 'vehicle.license_plate',
          title: 'Kenteken',
          sortField: 'vehicle.license_plate'
        },
        {
          name: 'vehicle.make.name',
          title: 'Merk',
        },
        {
          name: 'vehicle.model.name',
          title: 'Model',
        },
        {
          name: 'supplier_address.supplier.name',
          title: 'Leverancier',
          sortField: 'name'
        },
        {
          name: 'options_step_completed',
          title: 'Opties ingevuld',
        },
        {
          name: 'vehicle_data_step_completed',
          title: 'Data ingevuld',
        },
        'actions'
      ],
      actions: [
        {
          icon: '<i class="material-icons">visibility</i>',
          fn: (e) => {
            this.$router.push(`/dashboard/inkoop/aankoop/technische-voertuig-informatie/${e.vehicle.id}`)
          }
        }
      ]
    }
  },
  mounted() {
  },
  methods: {
    async getPurchases() {
      this.list = (await this.$api.purchase.list()).data
    }
  }
}
</script>

<style scoped>

</style>
